<template>
  <div class="vendedores mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Informe de ventas por vendedor</v-card-title>
          <v-card-subtitle>
            Desde este apartado encontrarás el promedio de productos facturados
            por tus profesionales, en el rango de fecha seleccionado.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :search="search"
                  dense
                  :headers="headers"
                  :items="vendedores"
                  :loading="loading"
                  :footer-props="{ itemsPerPageText: 'Vendedores' }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row no-gutters>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            class="ml-2"
                            color="black"
                            dark
                            @click="download()"
                          >
                            <v-icon left>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v-col>
                        <!-- <v-col cols="2">
                          <v-btn color="black" dark>
                            <v-icon left>mdi-download</v-icon>
                            PDF
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.promedio="{ item }">
                    {{ item.promedio | currency }}
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    {{ item.subtotal | currency }}
                  </template>
                  <template v-slot:item.iva="{ item }">
                    {{ item.iva | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="openDetalle(item)"
                          class="my-1"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card v-if="detalle">
                    <v-card-title>
                      {{ detalle.vendedor }}
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="search_detalle"
                        dense
                        :headers="SubHeader"
                        :items="detalle.data"
                        class="mt-2"
                        :footer-props="{ itemsPerPageText: 'Productos' }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-row dense>
                              <v-col cols="4" class="px-0">
                                <v-text-field
                                  outlined
                                  rounded
                                  dense
                                  append-icon="mdi-magnify"
                                  label="Buscar"
                                  v-model="search_detalle"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-btn
                                  @click="downloadDetalle()"
                                  color="black"
                                  dark
                                >
                                  <v-icon>mdi-download</v-icon>
                                  Descargar
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.promedio="{ item }">
                          {{ item.promedio | currency }}
                        </template>
                        <template v-slot:item.subtotal="{ item }">
                          {{ item.subtotal | currency }}
                        </template>
                        <template v-slot:item.iva="{ item }">
                          {{ item.iva | currency }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ item.total | currency }}
                        </template>
                        <template v-slot:body.append>
                          <tr>
                            <th>
                              Totales
                            </th>
                            <th colspan="2">
                              {{ totalizacion.cantidad }}
                            </th>
                            <th>
                              {{ totalizacion.subtotal | currency }}
                            </th>
                            <th>
                              {{ totalizacion.iva | currency }}
                            </th>
                            <th>
                              {{ totalizacion.total | currency }}
                            </th>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    menu_1: false,
    menu_2: false,
    loading: false,
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    search: "",
    search_detalle: "",
    headers: [
      {
        text: "Vendedor",
        value: "profesional",
        align: "start",
        sortable: false,
      },
      {
        text: "Cantidad",
        value: "cantidad",
        filterable: false,
      },
      {
        text: "Valor unidad promedio",
        value: "promedio",
        filterable: false,
        sortable: false,
      },
      {
        text: "Subtotal",
        value: "subtotal",
        filterable: false,
        sortable: false,
      },
      {
        text: "IVA",
        value: "iva",
        filterable: false,
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
        filterable: false,
        sortable: false,
      },
      {
        text: "Detalle",
        value: "detalle",
        filterable: false,
        sortable: false,
      },
    ],
    vendedores: [],
    detalle: null,
    dialog: false,
    SubHeader: [
      {
        text: "producto",
        value: "producto",
        align: "start",
        sortable: false,
      },
      {
        text: "Cantidad",
        value: "cantidad",
        filterable: false,
      },
      {
        text: "Valor unidad promedio",
        value: "promedio",
        filterable: false,
        sortable: false,
      },
      {
        text: "Subtotal",
        value: "subtotal",
        filterable: false,
        sortable: false,
      },
      {
        text: "IVA",
        value: "iva",
        filterable: false,
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
        filterable: false,
        sortable: false,
      },
    ],
  }),
  methods: {
    loadVendedores() {
      this.loading = true;
      const body = {
        route: "/vendedores",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.vendedores = response.data.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    openDetalle(item) {
      this.detalle = {
        vendedor: item.profesional,
        cedula: item.cedula,
        data: item.detalle.map((producto) => {
          producto.promedio = parseFloat(producto.promedio);
          producto.subtotal = parseFloat(producto.subtotal);
          producto.iva = parseFloat(producto.iva);
          producto.total = parseFloat(producto.total);
          return producto;
        }),
      };
      this.dialog = true;
    },
    download() {
      const name = "Vendedores";
      let data = this.vendedores.map((vendedor) => {
        return {
          Columna_A: vendedor.profesional,
          Columna_B: vendedor.cantidad,
          Columna_C: vendedor.promedio,
          Columna_D: vendedor.subtotal,
          Columna_E: vendedor.iva,
          Columna_F: vendedor.total,
        };
      });

      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: moment(this.date).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Fecha final",
          Columna_B: moment(this.date_fin).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Vendedor",
          Columna_B: "Cantidad",
          Columna_C: "Valor unidad promedio",
          Columna_D: "Subtotal",
          Columna_E: "IVA",
          Columna_F: "Total",
        }
      );
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    downloadDetalle() {
      const name = `Vededor-${this.detalle.cedula}`;
      let data = this.detalle.data.map((producto) => {
        return {
          Columna_A: producto.producto,
          Columna_B: producto.cantidad,
          Columna_C: producto.promedio,
          Columna_D: producto.subtotal,
          Columna_E: producto.iva,
          Columna_F: producto.total,
        };
      });

      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: moment(this.date).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Fecha final",
          Columna_B: moment(this.date_fin).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
        },
        {
          Columna_A: "Producto",
          Columna_B: "Cantidad",
          Columna_C: "Valor unidad promedio",
          Columna_D: "Subtotal",
          Columna_E: "IVA",
          Columna_F: "Total",
        }
      );
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadVendedores();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    totalizacion() {
      let data = {
        cantidad: 0,
        subtotal: 0,
        iva: 0,
        total: 0,
      };

      if (this.detalle) {
        data.cantidad = this.detalle.data.reduce((index, item) => {
          return index + parseFloat(item.cantidad);
        }, 0);
        data.subtotal = this.detalle.data.reduce((index, item) => {
          return index + parseFloat(item.subtotal);
        }, 0);
        data.iva = this.detalle.data.reduce((index, item) => {
          return index + parseFloat(item.iva);
        }, 0);
        data.total = this.detalle.data.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0);
      }
      return data;
    },
  },
  watch: {
    date() {
      this.loadVendedores();
      return this.date;
    },
    date_fin() {
      this.loadVendedores();
      return this.date_fin;
    },
  },
};
</script>
